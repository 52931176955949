
@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap');


* {
  font-family: 'Almarai', sans-serif !important;
}

/* Works on Chrome, Edge, and Safari */
[data-theme=blue] *::-webkit-scrollbar {
    width: 8px;
}
[data-theme=blue] *::-webkit-scrollbar-track {
    background: var(--scroll-bg);
    background-color: #9fd4e5!important;
    width: 50px!important;
    border-radius: 20px;
}
[data-theme=blue] *::-webkit-scrollbar-thumb {
    background-color: var(--scroll-color);
    border-radius: 20px;
    width: 50px!important;
    background-color: #267dee!important;
}
[data-theme=blue] * {
    scrollbar-width: thin;
    scrollbar-color: var(--scroll-color) var(--scroll-bg);
}
[data-theme=blue] .awesomplete .input-with-feedback {
    border: 1px solid var(--border-color);
}
[data-theme=blue] .form-control {
    border: 1px solid var(--border-color);
}
[data-theme=blue] .search-bar .awesomplete input {
    background-color: var(--bg-color)
}
[data-theme=blue] .modal-backdrop{
    background-color: #2c436b !important;
}
[data-theme=blue] .widget .widget-head .widget-title {
    font-size: var(--text-base);
}
[data-theme=blue] .comment-box .comment-input-header, .form-dashboard-section .section-head, .form-section .section-head, .head-title {
    font-size: var(--text-base);
    font-weight: 600;
}
[data-theme=blue] .comment-box .comment-input-container .ql-editor {
    border: 1px solid var(--border-color);
}
[data-theme=blue] .duration-picker .duration-input {
    background-color: var(--bg-color);
    border: 1px solid var(--border-color);
}
[data-theme=blue] .control-label {
    font-weight: 500;
}
[data-theme="dark"] {
    --bg-color: var(--gray-900);
    --disabled-control-bg: var(--gray-900);
    --border-color: #1c2126;
    --margin-lg : 15px;
    --text-bold: 600;
    --scroll-bg: #161a1f;
    --scroll-color: #1c2126;
}

[data-theme="light"] {
    --bg-color: #f2f5fa;
    --text-bold: 600;
    --control-bg : #fff;
    --control-bg-on-gray: #d4dcea;
    --disabled-control-bg: #f2f5fa;
    --heading-color: #333;
    --border-color:#e3e8f1;
    --avatar-frame-bg: #dde2ea;
    --margin-lg: 15px;
    --sidebar-select-color: #d4dcea;
    --awesomplete-hover-bg: #d4dcea;
    --scroll-bg: #eef1f5;
    --scroll-color: #d9dfe6;
}


/*****  OVERRIDDEN CSS  *****/

/* Heading Text Color */
/*
.page-head{
    background: #7C7BAD !important;
    background: #7C7BAD !important;
}
.page-container{
    background: url(/web_responsive/static/src/css/../img/home-menu-bg-overlay.svg), linear-gradient(to bottom, #7C7BAD, #c3c2cc); !important;
    background-color: url(/web_responsive/static/src/css/../img/home-menu-bg-overlay.svg), linear-gradient(to bottom, #7C7BAD, #c3c2cc); !important;
}
h3.ellipsis.title-text,
.widget-group .widget-group-head .widget-group-title,
.layout-side-section .sidebar-label{
    color: white !important;
}

*/
/* Form Input Focus *//*

input:focus{
    border: 1px solid #7C7BAD !important;
}
.form-control:focus{
    box-shadow: 0 0 0 2px rgba(94, 108, 162, 0.25) !important;
}

*/
/* Div Padding *//*

.widget.widget-shadow.shortcut-widget-box{
    padding: 5px 10px;
}

*/
/* List Design *//*

.indicator-pill, .indicator-pill-right{
    padding: 7px 8px;
    height: unset;
}

*/
/* Collapse Div Padding *//*

.section-head.collapsed{
    padding: 2px 4px;
}

*/
/* Dropdown List Hover */

[data-theme=blue] .awesomplete > ul > li:hover, .awesomplete > ul > li[aria-selected="true"]{
    background-color: #d7e6f1;
    color: black;
}
[data-theme=blue] .awesomplete input[type=text]{
    color: #000000;
}

[data-theme=blue] [data-label="Create Workspace"]{
    background-color: #ffa00a;
    color: #fff;
}


/* Global CSS */
/*     var(--hover-font-color)  */
/* Colours */
[data-theme=blue] {
    --body-bg-color:#a3f3f312;
    --background-color: #36414c;
    --primary-color: #2f81d1;
    --secondary-color: #7574ff;
    --white-color: #ffffff;
    --black-color: #000000;
    --gray-color: #888;
    --page-head-height: 50px;
    --navbar-height: 50px;
    --btn-default-class:#2196F3;
    --btn-default-color-hover-class:white;
    --btn-primary-bg-color:#19D895;
    --btn-primary-bg-color-hover:#14a572;
    --btn-primary-bg-color-hover:#14a572;
    --navbar-first-color:var(--sidebar-icon-color);
    --navbar-second-color:#17BBF1;
    --non-color-class:#ffffff00;
    --title-color:#0F7AD5;
    --sidebar-title:#3674ad;
    --sidebar-icon-color:#267DEE;
    --sidebar-icon-selected-color:#ffffff;
    --sidebar-font-selected-color:#fff;
    --hover-bg-color:#1CBDF3;
    --hover-font-color:white;
    --header-bg-color:#C1E2FC;
    --card-pill-bg-color:#D3EAFD;
    --card-pill-bg-hover-color:#F4F5F6;
    --header-for-create-doc-color:#68bafb;
    --input-read-only-color:#d3eafd75;
    --input-field-write:#1F9CF0;
    --form-control:#f2f2f2;
    --form-control-write:rgba(255, 160, 10, 0.14);
    --form-grid-color:#bbbbbb;
    --grid-heading-row-color:#f4f4f4;
    --row-col-color:#d1d8dd;
    --grid-row-hover-color:rgba(255, 152, 0, 0.15);
    --datatable-color:#f4f4f4;
    --btn-shadow-box:#28c5f924;
    --btn-primary-shadow-box:#6aedbb96;
    --text-editor-bg-color:#40abb933;
    --counter-color:#568fc5;
    --button-secondary-dark-color:#2196f3; 


    
}



[data-theme=blue] .page-container{
    background-color: var(--body-bg-color)!important;
}
[data-theme=blue] .btn-primary:active,
[data-theme=blue] .btn-secondary:focus,
[data-theme=blue] .btn-secondary:active,
[data-theme=blue] .btn-secondary:focus{
    box-shadow: none !important;
}
[data-theme=blue] #body{
    background-color: #F4F4F8;
}
[data-theme=blue] .btn-primary{
    color: var(--hover-font-color) !important;
    background-color: var(--btn-primary-bg-color)!important;
    border-radius: 5px !important;
    box-shadow: 0px 0px 0px 1px var(--btn-shadow-box)!important;


}
[data-theme=blue] .btn-primary:hover{
    background-color: var(--btn-primary-bg-color-hover) !important;
    color:var(--btn-default-color-hover-class)!important
}



[data-theme=blue] .btn-secondary{
    color: var(--btn-default-class) !important;
    background-color: var(--non-color-class)!important;
    border:1px solid var(--btn-default-class)!important;
    border-radius: 5px !important;
}

[data-theme=blue] .btn-secondary:hover{
    background-color: var(--btn-default-class) !important;
    color:var(--btn-default-color-hover-class)!important;
}

[data-theme=blue] .btn-default{
    color: var(--btn-default-class) !important;
    background-color: white!important;
    border:1px solid var(--btn-default-class)!important;
    border-radius: 5px !important;
    box-shadow: 0px 0px 0px 1px var(--btn-shadow-box)!important;
}
[data-theme=blue] .btn-default:hover{
    background-color: var(--btn-default-class) !important;
    color:var(--btn-default-color-hover-class)!important;
}

/* .btn-secondary{
    background-color: #606060 !important;
    color: var(--white-color) !important;
    border-radius: 0 !important;
    transition: background-color ease-in-out .15s;
}
.btn-secondary:hover{
    background-color: rgba(255, 120, 10, 0.93) !important;
} */
[data-theme=blue] .page-head,
[data-theme=blue] .page-head .page-head-content{
    height: var(--page-head-height);
}
[data-theme=blue] .page-head,
[data-theme=blue] .page-head .page-head-content{
    top: var(--page-head-height);
}



/* Top Header */
[data-theme=blue] .navbar{
    background:linear-gradient(to right ,var(--navbar-first-color),var(--navbar-second-color)) !important;
    height: var(--navbar-height);
}
[data-theme=blue] .navbar-expand{
    background-color: var(--background-color);
}
[data-theme=blue] #navbar-breadcrumbs > li > a::before{
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    margin-left: .3em;
    display: inline-block;

    font-size: 24px;
    transition: 0.2s;
    position: relative;
    top: 3px;
    content: "\f105";
    margin-left: 10px;
    color: var(--white-color);
}
[data-theme=blue] #navbar-breadcrumbs a{
    color: var(--white-color);
    text-decoration: none;
}
[data-theme=blue] header .form-control{
    color: var(--white-color);
}
[data-theme=blue] header .form-control:focus{
    border: 0;
}
[data-theme=blue] .search-bar .awesomplete input{
    height: 32px;
}
/*#navbar-search{
    width: 300px;
    background-color: rgb(65, 78, 90);
    border-radius: 0px;
    color: #f9f9f9;
    border: none;
}*/
[data-theme=blue] header ul#navbar-breadcrumbs{
    margin-top: -8px;
}
[data-theme=blue] header .form-control:focus{
    background-color: var(--white-color) !important;
}



/* Sidebar */
/* header in sidebar  */

[data-theme=blue] .flex .title-text{
    color: var(--title-color);
}
[data-theme=blue] .page-title .sidebar-toggle-btn  {
    --icon-stroke: var(--sidebar-title);
}
/* element in sidebar */
/* element when selected */
[data-theme=blue] .standard-sidebar-item use{
    --icon-stroke: var(--sidebar-icon-color);

}
[data-theme=blue] .standard-sidebar-item.selected{
    background:var(--sidebar-icon-color) !important;
}
[data-theme=blue] .standard-sidebar-item.selected use{
    --icon-stroke: var(--sidebar-icon-selected-color);

}
[data-theme=blue] .standard-sidebar-item.selected a{
    color:var(--sidebar-font-selected-color);
}
[data-theme=blue] .standard-sidebar-item:hover{
    background:var(--hover-bg-color) !important;
}
[data-theme=blue] .standard-sidebar-item:hover use{
    --icon-stroke: var(--sidebar-icon-selected-color);

}
[data-theme=blue] .standard-sidebar-item:hover a{
    color:var(--sidebar-font-selected-color);
}
[data-theme=blue] .layout-side-section .sidebar-label{
    color: var(--title-color);
}
[data-theme=blue] .sidebar-label svg,
[data-theme=blue] .layout-side-section .sidebar-label .icon{
    stroke: var(--primary-color);
}
[data-theme=blue] .list-sidebar-button{
    background-color: var(--non-color-class) !important;
    box-shadow: none !important;
}
[data-theme=blue] .list-sidebar-button:active,
[data-theme=blue] .list-sidebar-button:focus{
    text-decoration: none !important;
    box-shadow: none !important;
}

/* Main Section Layout */
/* wedgit link (card content) */
[data-theme=blue] .onboarding-steps-wrapper a:hover{
    background-color: var(--hover-bg-color)!important;
    color: var(--hover-font-color)!important;
}
[data-theme=blue] .onboarding-steps-wrapper a.active{
    background-color: var(--sidebar-icon-color)!important;
    color: var(--hover-font-color)!important;
}
[data-theme=blue] .widget-body .link-item:hover{
    color: var(--sidebar-font-selected-color);
    background: var(--hover-bg-color);
}
[data-theme=blue] .indicator-pill.gray,
[data-theme=blue] .indicator-pill-right.gray,
[data-theme=blue] .indicator-pill-round.gray {
    background: var(--card-pill-bg-color);
    color: var(--title-color);
}
[data-theme=blue] .indicator-pill.gray::before{
    background:var(--title-color)
}
[data-theme=blue] .indicator-pill.yellow{
    background: var(--card-pill-bg-color) !important;
    color: 0f7ad5!important;
    --icon-stroke: var(--white-color)!important;
}
[data-theme=blue] .indicator-pill.yellow::before{
    background:var(--title-color)
}


/* main body for section  */

[data-theme=blue] .layout-main-section-wrapper .layout-main-section{
    border:1px solid var(--hover-bg-color);
    border-radius: 10px;
}
[data-theme=blue] .ce-block__content .links-widget-box .widget-head .widget-label .widget-title{
    color: var(--sidebar-icon-color);
}
[data-theme=blue] .ce-block__content .links-widget-box .widget-head .widget-label .widget-title use{
    --icon-stroke: var(--sidebar-icon-color);
}
[data-theme=blue] .h4{
    color: var(--title-color);
}


/* list view document page */
/* header action bar */

/* [data-theme=blue] .flex.col.page-actions.justify-content-end .icon{
    background-color: white;
    --icon-stroke:#fff!important
} */

/* header list document section action bar and filter  */
[data-theme=blue] .input-xs {
    height: 24px!important;
    padding:5px!important
}
[data-theme=blue] .page-form.flex {
    background-color: var(--header-bg-color);
}
[data-theme=blue] .btn-new-doc {
    background-color: var(--header-bg-color)!important;
}
[data-theme=blue] .page-actions use {
    --icon-stroke:var(--title-color)!important;
    
}
[data-theme=blue] .page-actions button:hover use{
    --icon-stroke:var(--sidebar-icon-selected-color)!important;

}
[data-theme=blue] .filter-section use {
    --icon-stroke:var(--title-color)!important;
    
}

[data-theme=blue] .filter-section button:hover use{
    --icon-stroke:white!important;

}
 




/* create new document */

[data-theme=blue] .section-head {
    color:var(--title-color)!important;
}
[data-theme=blue] .form-page .form-tabs-list {
    background-color: var(--header-for-create-doc-color)!important;
    color:var(--hover-font-color)!important;

}
[data-theme=blue] .form-page .form-tabs-list a {
    color:var(--hover-font-color)!important;
}
[data-theme=blue] .grid-heading-row{
    border-bottom:none!important;
    background-color:var(--header-bg-color)!important ;
}
[data-theme=blue] .input-with-feedback.form-control{
    background-color: var(--card-pill-bg-color)!important;
    color: var(--title-color);
}
[data-theme=blue] .like-disabled-input{
    background-color: var(--input-read-only-color)!important;
}

[data-theme=blue] .standard-filter-section select{
    background-color: var(--title-color)!important;
    
}

[data-theme=blue] .input-with-feedback.form-control:focus{
    background-color: var(--card-pill-bg-color)!important;

    border:1px solid var(--input-field-write)!important ;
    /* border-radius: 10px; */
    color: var(--title-color);
    
}


/* Input Fields */

[data-theme=blue] .form-control{
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    background-color: #f2f2f2 !important;
}
[data-theme=blue] .form-control:focus{
    outline: 0;
    border: 1px solid var(--primary-color);
    box-shadow: none;
    background: var(--form-control-write) !important;
}
[data-theme=blue] .navbar .navbar-nav a.nav-link{
    color: var(--white-color);
}
[data-theme=blue] span.notifications-seen use,
[data-theme=blue] symbol#icon-small-down{
    fill: var(--non-color-class)!important;
    stroke: var(--hover-font-color)!important;
    --icon-stroke: var(--sidebar-icon-selected-color);


}


/* Table */

[data-theme=blue] .form-grid{
    border-radius: 0;
    border: 1px solid var(--form-grid-color);
}
[data-theme=blue] .form-grid .grid-heading-row{
    background-color: var(--grid-heading-row-color);
}
[data-theme=blue] .grid-heading-row{
    border-bottom: 1px solid var(--primary-color);
}
[data-theme=blue] .grid-row > .row .col{
    border-left: 1px solid var(--row-col-color);
}
[data-theme=blue] .form-grid .grid-row:hover{
    background: var(--grid-row-hover-color);
}

/* Heading Color */
[data-theme=blue] .head-title, .form-section .section-head, .form-dashboard-section .section-head, .comment-box .comment-input-header{
    color: var(--primary-color);
}

/* Lists */
[data-theme=blue] .list-subject a{
    text-decoration: none;
    transition: 0.3s;
}
[data-theme=blue] .list-subject a:hover{
    color: var(--secondary-color);
}


/* Report Table */
[data-theme=blue] .datatable .dt-header .dt-cell--header .dt-cell__content{
    background-color: var(--datatable-color);
}

[data-theme=blue] .btn{
    box-shadow: 0px 0px 0px 1px var(--btn-shadow-box);
    outline:none !important;
}





[data-theme=blue] .btn.btn-sm.filter-button.btn-primary-light{
    color: var(--btn-default-class) !important;
    background-color: white!important;
    border:1px solid var(--btn-default-class)!important;
    border-radius: 5px !important;
    box-shadow: 0px 0px 0px 1px var(--btn-shadow-box)!important;
}
[data-theme=blue] .btn.btn-sm.filter-button.btn-primary-light:hover{
    background-color: var(--btn-default-class) !important;
    color:var(--btn-default-color-hover-class)!important;
}
[data-theme=blue] .sidebar-action.show-tags a{
    color: var(--btn-default-class) !important;
}
[data-theme=blue] .add-list-group-by.sidebar-action a{
    color: var(--btn-default-class) !important;
}
[data-theme=blue] .filter-button.btn-primary-light{
    outline:none !important;
}
[data-theme=blue] .standard-filter-section .input-with-feedback.form-control.input-xs{
    background-color: white !important;
}

[data-theme=blue] .grid-row .data-row.row.editable-row input:not([type="checkbox"]){
background-color: var(--input-read-only-color)!important;
}
[data-theme=blue] .ql-editor{
background-color: var(--text-editor-bg-color)!important;
}
[data-theme=blue] .document-link-badge{
background-color: var(--text-editor-bg-color)!important;
}
[data-theme=blue] .document-link use {
    --icon-stroke: var(--sidebar-icon-color)!important;
    stroke: var(--sidebar-icon-color)!important;
}
[data-theme=blue] .document-link button:hover use{
    --icon-stroke: var(--sidebar-icon-selected-color)!important;
    stroke: var(--sidebar-icon-selected-color)!important;
}
[data-theme=blue] .count{
    background-color: var(--counter-color)!important;
}

[data-theme=blue] .btn-secondary-dark{
    background-color: var(--button-secondary-dark-color)!important;
}
[data-theme=blue] .btn.btn-xs.btn-secondary-dark.action-btn:active{
    border:1px solid var(--button-secondary-dark-color)!important;
    outline:none !important;
    box-shadow: none!important;
}

[data-theme=blue] .form-sidebar.overlay-sidebar.hidden-xs.hidden-sm  use{
    --icon-stroke: var(--sidebar-icon-color)!important;
    stroke: var(--sidebar-icon-color)!important;
}
[data-theme=blue] .form-sidebar.overlay-sidebar.hidden-xs.hidden-sm  .data-pill {
   background-color: var(--btn-default-class)!important;
   color: white;
}   
[data-theme=blue] .form-sidebar.overlay-sidebar.hidden-xs.hidden-sm  .data-pill:active {
    border:2px solid var(--btn-default-class)!important;
    outline:none !important;
    box-shadow: none!important;
}   
[data-theme=blue] .form-sidebar.overlay-sidebar.hidden-xs.hidden-sm .data-pill use{
    --icon-stroke: var(--sidebar-icon-selected-color)!important;
    stroke: var(--sidebar-icon-selected-color)!important;
}
[data-theme=blue] .text-muted.btn.btn-default.icon-btn:hover use{
    --icon-stroke: var(--sidebar-icon-selected-color)!important;
    stroke: var(--sidebar-icon-selected-color)!important;
}
[data-theme=blue] .widget-body .link-item:hover .indicator-pill.no-margin.yellow{
    background: var(--card-pill-bg-hover-color) !important;
    color: var(--sidebar-font-selected-color)!important;
    fill:var(--sidebar-font-selected-color)!important;
    stroke: var(--sidebar-font-selected-color)!important;
    --icon-stroke: var(--sidebar-font-selected-color)!important;
}

[data-theme=blue] .widget-head .widget-control .indicator-pill.green{
    background: var(--card-pill-bg-color)!important;
    color: var(--title-color)!important;
}
[data-theme=blue] .widget-head .widget-control .indicator-pill.green::before{
    background: var(--title-color)!important;
}
[data-theme=blue] .widget-head .widget-control .indicator-pill.grey{
    background: var(--card-pill-bg-color)!important;
    color: var(--title-color)!important;
}
[data-theme=blue] .widget-head .widget-control .indicator-pill.grey::before{
    background: var(--title-color)!important;
}
[data-theme=blue] .filter-chart.btn.btn-xs.pull-right{
    background: white!important;
    border: 1px solid var(--btn-default-class)!important ;
}
[data-theme=blue] .filter-chart.btn.btn-xs.pull-right use{ 
    --icon-stroke: var(--sidebar-icon-color)!important;
    stroke: var(--sidebar-icon-color)!important;
}


[data-theme=blue] .filter-chart.btn.btn-xs.pull-right:hover{
    background: var(--btn-default-class)!important;
}
[data-theme=blue] .filter-chart.btn.btn-xs.pull-right:hover use{
    --icon-stroke: var(--sidebar-icon-selected-color)!important;
    stroke: var(--sidebar-icon-selected-color)!important;
}
[data-theme=blue] .chart-actions.dropdown.pull-right .btn.btn-xs.btn-secondary.chart-menu use{
    --icon-stroke: var(--sidebar-icon-color)!important;
    stroke: var(--sidebar-icon-color)!important;
}
[data-theme=blue] .chart-actions.dropdown.pull-right .btn.btn-xs.btn-secondary.chart-menu:hover use{
    --icon-stroke: var(--sidebar-icon-selected-color)!important;
    stroke: var(--sidebar-icon-selected-color)!important;
}

/* Buttons CSS
.filter-button,
.sort-selector-button,
.btn-order{
    background-color: #ea7272 !important;
    color: var(--white-color) !important;
    transition: 0.3s;
    border-radius: 0 !important;
}
.filter-button:hover,
.sort-selector-button:hover,
.btn-order:hover{
    background-color: #c83b3b !important;
}
.filter-button span.filter-icon{
    color: var(--white-color);
}
.filter-button span.filter-icon svg.icon{
    stroke: var(--white-color);
} */
